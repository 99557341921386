import React, { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';

toast.configure();

export const notify = (
  message,
  ToasterType = 'error',
  id,
  autoClose = true
) => {
  const toastId = id ? id : message;
  const options = {
    type: ToasterType,
    position: 'top-right',
    toastId: toastId,
    autoClose: autoClose,
    pauseOnFocusLoss: false,
    pauseOnHover: true,
  };
  const activeToastId = toast(message, options);
  if (toast.isActive(activeToastId)) {
    toast.update(toastId, {
      render: message,
      type: ToasterType,
      autoClose: autoClose,
    });
  } else {
    toast(message, options);
  }
};

export const createElementFromHTML = (htmlString) => {
  const div = document.createElement('div');
  div.innerHTML = htmlString.trim();

  // Change this to div.childNodes to support multiple top-level nodes
  return div.innerHTML;
};

// Below function is used to add styles to the captioned images in the editor
export const replaceCaptionedImageStyle = (data) => {
  const regexFigure = /<figure/gi;
  const replaceFigure =
    '<figure style="display: inline-block; margin-left: 0;" ';
  data = data.replace(regexFigure, replaceFigure);
  const regexFigcaption = /<figcaption/gi;
  const replaceFigcaption = '<figcaption style="text-align: center;" ';
  data = data.replace(regexFigcaption, replaceFigcaption);
  return data;
};

export const toSentanceCase = (txt) => {
  return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
};

/* eslint-disable no-useless-escape */
export const isEmail = (email) => {
  return /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
    email
  );
};
export const PUBLIC_PAGES = [
  '/logout',
  '/register',
  '/recover',
  '/lock',
  '/notfound',
  '/error500',
  '/maintenance',
  '/reset_password',
  '/confirmation',
  '/easuccess.action',
  '/noCrmAccountFound',
  '/unableToLink',
  '/crmOrgIdMismatch',
  '/failedToUpdateCrmUserId',
];

export const KoncertProducts = {
  CLICK_DIALER: 'Click Dialer',
  FLOW_DIALER: 'Flow Dialer',
  AGENT_ASSISTED_DIALER: 'Agent Assisted Dialer',
  AI_DIALER_MULTI_LINE: 'AI Parallel Dialer',
  AI_DIALER_SINGLE_LINE: 'AI Flow Dialer',
};

export const PUBLIC_CALENDAR_PAGE = '/meetings/invite';

// Emails Tab constants are available here
export const emailTabs = {
  PENDING: 'pending',
  DRAFT: 'draft',
  REPLIED: 'replied',
  SCHEDULED: 'scheduled',
  BOUNCED: 'bounced',
  FAILED: 'failed',
  SENT: 'sent',
  AUTO_EMAIL: 'Auto Email',
};

export const standardFields = [
  'firstName',
  'lastName',
  'title',
  // TODO Change to email
  'emailId',
  'phone',
  'accountName',
  'contactName',
];

export const clearBrowserCache = (actions) => {
  actions.resetUsers();
  actions.resetAccounts();
  actions.resetCadences();
  actions.resetTags();
  actions.resetPendingCallsCount();
  actions.resetToDoCount();
  actions.resetClickDialerData();

  sessionStorage.removeItem('sessionId');
  localStorage.removeItem('externalMeetingId');
  localStorage.removeItem('phoneLoginMethod');
};

export const handleTextOverFlow = (string, length = 30) => {
  if (string.length > length) {
    return string.substring(0, length) + '...';
  }
  return string;
};

export const handleLogout = (actions, logout, returnToLogin, pathname) => {
  clearBrowserCache(actions);
  logout({
    returnTo: `${window.location.origin}${
      returnToLogin ? '' : pathname ? pathname : '/logout'
    }`,
  });
};

export const useClickOutside = (handler, isActive) => {
  const domNode = useRef(null);

  useEffect(() => {
    const clickHandler = (e) => {
      if (isActive && !domNode.current.contains(e.target)) {
        handler();
      }
    };
    document.addEventListener('mousedown', clickHandler);
    return () => {
      document.removeEventListener('mousedown', clickHandler);
    };
  });
  return domNode;
};

export const htmlEncode = (word) => {
  const element = document.createElement('div');
  element.innerText = element.textContent = word;
  word = element.innerHTML;
  return word;
};

export const formatDateTime = (inputDateString) => {
  if (inputDateString) {
    return moment(inputDateString).format('M/D/YYYY h:mm A');
  }
  return '';
};

export const getErrorMessage = (response, defaultMessage) => {
  return response.graphQLErrors[0]
    ? response.graphQLErrors[0].message
    : defaultMessage;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// this function is used to get date values from the date range string. This is used in reports, dashboard and emails pages
export const dateRangeToDateCalculator = (dateRange) => {
  let startDay = '';
  let endDay = '';
  const momentFormat = 'YYYY-MM-DD';
  const today = moment();
  const yesterday = moment().subtract(1, 'days');
  switch (dateRange.toLowerCase()) {
    case 'today':
      startDay = today.format(momentFormat);
      endDay = today.format(momentFormat);
      break;
    case 'yesterday':
      startDay = yesterday.format(momentFormat);
      endDay = yesterday.format(momentFormat);
      break;
    case 'current week':
      startDay = moment().startOf('week').format(momentFormat);
      endDay = moment().endOf('week').format(momentFormat);
      break;
    case 'last week':
      startDay = moment()
        .subtract(1, 'weeks')
        .startOf('week')
        .format(momentFormat);
      endDay = moment().subtract(1, 'weeks').endOf('week').format(momentFormat);
      break;
    case 'last 7 days':
      startDay = moment()
        .subtract(1, 'weeks')
        .startOf('week')
        .format(momentFormat);
      endDay = moment().subtract(1, 'weeks').endOf('week').format(momentFormat);
      break;
    case 'current month':
      startDay = moment().startOf('month').format(momentFormat);
      endDay = moment().endOf('month').format(momentFormat);
      break;
    case 'last month':
      startDay = moment()
        .subtract(1, 'months')
        .startOf('month')
        .format(momentFormat);
      endDay = moment()
        .subtract(1, 'months')
        .endOf('month')
        .format(momentFormat);
      break;
    case 'last 30 days':
      startDay = moment()
        .subtract(1, 'months')
        .startOf('month')
        .format(momentFormat);
      endDay = moment()
        .subtract(1, 'months')
        .endOf('month')
        .format(momentFormat);
      break;
    case 'current quarter':
      startDay = moment().startOf('quarter').format(momentFormat);
      endDay = moment().endOf('quarter').format(momentFormat);
      break;
    case 'last quarter':
      startDay = moment()
        .subtract(1, 'quarters')
        .startOf('quarter')
        .format(momentFormat);
      endDay = moment()
        .subtract(1, 'quarters')
        .endOf('quarter')
        .format(momentFormat);
      break;
    case 'custom':
      startDay = '';
      endDay = '';
      break;
    default:
      startDay = '';
      endDay = '';
  }
  return { startDate: startDay, endDate: endDay };
};

export const truncateString = function (str, length = 18, ending = '...') {
  return str
    ? str?.length > length
      ? str.substring(0, length - ending.length) + ending
      : str
    : '';
};

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text).then(
    function () {
      notify('Request Id has been copied to clipboard!', 'success');
    },
    function (err) {
      notify('Could not copy Request Id');
    }
  );
};

// This function is to show success message if action is done using job queue
export const jobQueueSuccessMessage = (
  message,
  requestId,
  id,
  autoClose = true
) => {
  const successMessage = (
    <>
      <h6>{message}</h6>
      <h6>This could take few minutes.</h6>
      {requestId && (
        <>
          <br />
          <span>RequestId: {requestId}</span>
          <i
            className="fas fa-copy ml-2 text-dark"
            title="Copy Request ID"
            onClick={() => copyToClipboard(requestId)}
          ></i>
        </>
      )}
    </>
  );
  return notify(successMessage, 'success', id, autoClose);
};

// This function is to show success message if the prospect action is done using job queue
export const showSuccessMsg = (requestId) => {
  const successMessage = `Your request has been submitted.`;
  const successFormat = (
    <>
      <h6>{successMessage}</h6>
      <h6>This could take few minutes.</h6>
      {requestId && (
        <>
          <br />
          <span>RequestId: {requestId}</span>
          <i
            className="fas fa-copy ml-2 text-dark"
            title="Copy Request ID"
            onClick={() => copyToClipboard(requestId)}
          ></i>
        </>
      )}
    </>
  );
  return notify(successFormat, 'success', 'prospect_action_success');
};

export const showErrorMessage = (
  response,
  defaultMessage,
  errorData,
  id,
  autoClose = true
) => {
  let errorMsg =
    response.graphQLErrors && response.graphQLErrors.length > 0
      ? response.graphQLErrors[0].message === 'shared user ids are  mandatory.'
        ? 'User names are mandatory'
        : response.graphQLErrors[0].message
      : response.networkError
      ? response.networkError.message
      : defaultMessage;
  if (errorMsg === 'Timeout exceeded') {
    errorMsg =
      'Some information did not load properly, please refresh and try again.';
  }

  const requestId = errorData?.requestId;
  const errorFormat = (
    <>
      <h6>{errorMsg}</h6>
      {requestId && (
        <>
          <br />
          <span>RequestId: {requestId}</span>
          <i
            className="fas fa-copy ml-2 text-dark"
            title="Copy Request ID"
            onClick={() => copyToClipboard(requestId)}
          ></i>
        </>
      )}
    </>
  );
  return notify(errorFormat, 'error', id, autoClose);
};

export const trimValue = (value) => {
  return value && value !== 'null'
    ? typeof value === 'string'
      ? value.trim()
      : value
    : '';
};

export const decodeURIComponentSafe = (str) => {
  if (!str) {
    return str;
  }
  return decodeURIComponent(str.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25'));
};

export const getDueDate = (date) => {
  let result = '';
  const array = trimValue(date).split(' ');
  for (let index = 0; index < array.length; index++) {
    const value = array[index];
    if (index % 2 === 0 && parseInt(value) > 0) {
      result += ` ${value} ${trimValue(array[index + 1]).toLowerCase()}`;
    }
  }
  result = result.trim().split(' ').slice(0, 4).join(' ');
  return result;
};

export const isValidURL = (textValue) => {
  if (textValue === undefined || textValue === null || textValue === '') {
    return false;
  }
  textValue = '' + textValue;

  const validUrl =
    textValue.indexOf('http://') === 0 ||
    textValue.indexOf('https://') === 0 ||
    textValue.indexOf('www.') === 0;
  return validUrl;
};

export const formatWebLink = (fieldValue) => {
  const isValidUrl = isValidURL(fieldValue);

  if (isValidUrl) {
    fieldValue = fieldValue.startsWith('http')
      ? fieldValue
      : 'https://' + fieldValue;
  }
  return fieldValue;
};

export const timeLeft = (moment, dueAtDate, pausedDateTime) => {
  const dueDate = moment(new Date(dueAtDate));
  const now = pausedDateTime ? moment(pausedDateTime) : moment(new Date());
  const diffDuration = moment.duration(dueDate.diff(now));
  let result = '';
  const days = Math.floor(diffDuration.asDays());
  if (days > 0) {
    result += days + (days === 1 ? ' day ' : ' days ');
  }
  const hours = diffDuration.hours();
  if (hours > 0) {
    result += hours + (hours === 1 ? ' hour ' : ' hours ');
  }
  const minutes = diffDuration.minutes();
  if (minutes > 0) {
    result += minutes + (minutes === 1 ? ' min ' : ' mins ');
  }
  const seconds = diffDuration.seconds();
  if (seconds > 0) {
    result += seconds + (seconds === 1 ? ' sec ' : ' secs ');
  }
  return `${result.trim()}`;
};

export const metricFormat = (metric, noDecimalRequired) => {
  const formattedMetric =
    parseInt(metric) > 999
      ? noDecimalRequired
        ? `${parseInt(metric) / 1000}K`
        : `${(parseInt(metric) / 1000).toFixed(1)}K`
      : metric;

  return formattedMetric;
};

export const convertDateFormat = (data) => {
  const date = data.split('-');
  return date[1] + '/' + date[2] + '/' + date[0];
};

export const FAILED_TO_FETCH_DATA =
  'Sorry! Failed to fetch data. Please try again.';
export const NO_DATA_AVAILABLE = 'No data available.';
export const PLEASE_CONTACT_CONNECTLEADER_SUPPORT =
  'Please contact Koncert Support';
export const SORRY_NO_DATA_AVAILABLE = 'Sorry! No data available.';
export const PROSPECTS_IN_MY_SESSION =
  'Prospect(s) cannot be transferred because they have been uploaded to be dialed. Please clear your list and try again.';
export const DELETE_PROSPECT_IN_MY_SESSION =
  'Prospect(s) cannot be deleted because they have been uploaded to be dialed. Please clear your list and try again.';

export const weekDaysList = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const fullProductName = {
  PD: 'Flow Dialer',
  TD: 'Team Dialer',
  AIDML: 'AI Parallel Dialer',
  AIDSL: 'AI Flow Dialer',
  CD: 'Click Dialer',
  TC: 'Cadence',
  CO: 'Coverage',
};

export const timezones = [
  {
    zone: 'Pacific/Samoa',
    displayName: '(GMT-11:00) Samoa Standard Time(Pacific/Samoa)',
  },
  {
    zone: 'Pacific/Pago_Pago',
    displayName: '(GMT-11:00) Samoa Standard Time(Pacific/Pago_Pago)',
  },
  {
    zone: 'Pacific/Midway',
    displayName: '(GMT-11:00) Samoa Standard Time(Pacific/Midway)',
  },
  {
    zone: 'Pacific/Niue',
    displayName: '(GMT-11:00) Niue Time(Pacific/Niue)',
  },
  {
    zone: 'Pacific/Tahiti',
    displayName: '(GMT-10:00) Tahiti Time(Pacific/Tahiti)',
  },
  {
    zone: 'Pacific/Johnston',
    displayName: '(GMT-10:00) Hawaii-Aleutian Standard Time(Pacific/Johnston)',
  },
  {
    zone: 'Pacific/Honolulu',
    displayName: '(GMT-10:00) Hawaii-Aleutian Standard Time(Pacific/Honolulu)',
  },
  {
    zone: 'Pacific/Rarotonga',
    displayName: '(GMT-10:00) Cook Islands Standard Time(Pacific/Rarotonga)',
  },
  {
    zone: 'Pacific/Marquesas',
    displayName: '(GMT-09:30) Marquesas Time(Pacific/Marquesas)',
  },
  {
    zone: 'America/Atka',
    displayName: '(GMT-09:00) Hawaii-Aleutian Daylight Time(America/Atka)',
  },
  {
    zone: 'America/Adak',
    displayName: '(GMT-09:00) Hawaii-Aleutian Daylight Time(America/Adak)',
  },
  {
    zone: 'Pacific/Gambier',
    displayName: '(GMT-09:00) Gambier Time(Pacific/Gambier)',
  },
  {
    zone: 'Pacific/Pitcairn',
    displayName: '(GMT-08:00) Pitcairn Time(Pacific/Pitcairn)',
  },
  {
    zone: 'America/Yakutat',
    displayName: '(GMT-08:00) Alaska Daylight Time(America/Yakutat)',
  },
  {
    zone: 'America/Sitka  ',
    displayName: '(GMT-08:00) Alaska Daylight Time(America/Sitka)',
  },
  {
    zone: 'America/Nome',
    displayName: '(GMT-08:00) Alaska Daylight Time(America/Nome)',
  },
  {
    zone: 'America/Metlakatla',
    displayName: '(GMT-08:00) Alaska Daylight Time(America/Metlakatla)',
  },
  {
    zone: 'America/Juneau',
    displayName: '(GMT-08:00) Alaska Daylight Time(America/Juneau)',
  },
  {
    zone: 'America/Anchorage',
    displayName: '(GMT-08:00) Alaska Daylight Time(America/Anchorage)',
  },
  {
    zone: 'America/Whitehorse',
    displayName: '(GMT-07:00) Pacific Standard Time(America/Whitehorse)',
  },
  {
    zone: 'America/Dawson',
    displayName: '(GMT-07:00) Pacific Standard Time(America/Dawson)',
  },
  {
    zone: 'America/Vancouver',
    displayName: '(GMT-07:00) Pacific Daylight Time(America/Vancouver)',
  },
  {
    zone: 'America/Tijuana',
    displayName: '(GMT-07:00) Pacific Daylight Time(America/Tijuana)',
  },
  {
    zone: 'America/Los_Angeles',
    displayName: '(GMT-07:00) Pacific Daylight Time(America/Los_Angeles)',
  },
  {
    zone: 'America/Ensenada',
    displayName: '(GMT-07:00) Pacific Daylight Time(America/Ensenada)',
  },
  {
    zone: 'America/Santa_Isabel',
    displayName:
      ' (GMT-07:00) Northwest Mexico Daylight Time(America/Santa_Isabel)',
  },

  {
    zone: 'America/Phoenix',
    displayName: '(GMT-07:00) Mountain Standard Time(America/Phoenix)',
  },
  {
    zone: 'America/Fort_Nelson',
    displayName: '(GMT-07:00) Mountain Standard Time(America/Fort_Nelson)',
  },
  {
    zone: 'America/Dawson_Creek',
    displayName: '(GMT-07:00) Mountain Standard Time(America/Dawson_Creek)',
  },
  {
    zone: 'America/Creston',
    displayName: '(GMT-07:00) Mountain Standard Time(America/Creston)',
  },
  {
    zone: 'America/Hermosillo',
    displayName:
      '(GMT-07:00) Mexican Pacific Standard Time(America/Hermosillo)',
  },
  {
    zone: 'America/Yellowknife',
    displayName: '(GMT-06:00) Mountain Daylight Time(America/Yellowknife)',
  },
  {
    zone: 'America/Shiprock',
    displayName: '(GMT-06:00) Mountain Daylight Time(America/Shiprock)',
  },
  {
    zone: 'America/Ojinaga',
    displayName: '(GMT-06:00) Mountain Daylight Time(America/Ojinaga)',
  },
  {
    zone: 'America/Inuvik',
    displayName: '(GMT-06:00) Mountain Daylight Time(America/Inuvik)',
  },
  {
    zone: 'America/Edmonton',
    displayName: '(GMT-06:00) Mountain Daylight Time(America/Edmonton)',
  },
  {
    zone: 'America/Denver',
    displayName: '(GMT-06:00) Mountain Daylight Time(America/Denver)',
  },
  {
    zone: 'America/Cambridge_Bay',
    displayName: '(GMT-06:00) Mountain Daylight Time(America/Cambridge_Bay)',
  },

  {
    zone: 'America/Boise',
    displayName: '(GMT-06:00) Mountain Daylight Time(America/Boise)',
  },
  {
    zone: 'America/Mazatlan',
    displayName: '(GMT-06:00) Mexican Pacific Daylight Time(America/Mazatlan)',
  },
  {
    zone: 'America/Chihuahua',
    displayName: '(GMT-06:00) Mexican Pacific Daylight Time(America/Chihuahua)',
  },
  {
    zone: 'Pacific/Galapagos',
    displayName: '(GMT-06:00) Galapagos Time(Pacific/Galapagos)',
  },
  {
    zone: 'America/Tegucigalpa',
    displayName: '(GMT-06:00) Central Standard Time(America/Tegucigalpa)',
  },
  {
    zone: 'America/Swift_Current',
    displayName: '(GMT-06:00) Central Standard Time(America/Swift_Current)',
  },
  {
    zone: 'America/Regina',
    displayName: '(GMT-06:00) Central Standard Time(America/Regina)',
  },
  {
    zone: 'America/Managua',
    displayName: '(GMT-06:00) Central Standard Time(America/Managua)',
  },
  {
    zone: 'America/Guatemala',
    displayName: '(GMT-06:00) Central Standard Time(America/Guatemala)',
  },
  {
    zone: 'America/El_Salvador',
    displayName: '(GMT-06:00) Central Standard Time(America/El_Salvador)',
  },
  {
    zone: 'America/Costa_Rica',
    displayName: '(GMT-06:00) Central Standard Time(America/Costa_Rica)',
  },
  {
    zone: 'America/Belize',
    displayName: '(GMT-06:00) Central Standard Time(America/Belize)',
  },
  {
    zone: 'America/Lima',
    displayName: '(GMT-05:00) Peru Standard Time(America/Lima)',
  },
  {
    zone: 'America/Guayaquil',
    displayName: '(GMT-05:00) Ecuador Time(America/Guayaquil)',
  },
  {
    zone: 'America/Panama',
    displayName: '(GMT-05:00) Eastern Standard Time(America/Panama)',
  },
  {
    zone: 'America/Jamaica',
    displayName: '(GMT-05:00) Eastern Standard Time(America/Jamaica)',
  },
  {
    zone: 'America/Coral_Harbour',
    displayName: '(GMT-05:00) Eastern Standard Time(America/Coral_Harbour)',
  },
  {
    zone: 'America/Cayman',
    displayName: ' (GMT-05:00) Eastern Standard Time(America/Cayman)',
  },
  {
    zone: 'America/Cancun',
    displayName: '(GMT-05:00) Eastern Standard Time(America/Cancun)',
  },
  {
    zone: 'America/Atikokan',
    displayName: '(GMT-05:00) Eastern Standard Time(America/Atikokan)',
  },

  {
    zone: 'Pacific/Easter',
    displayName: '(GMT-05:00) Easter Island Daylight Time(Pacific/Easter)',
  },
  {
    zone: 'America/Bogota',
    displayName: '(GMT-05:00) Colombia Standard Time(America/Bogota)',
  },
  {
    zone: 'America/Winnipeg',
    displayName: '(GMT-05:00) Central Daylight Time(America/Winnipeg)',
  },
  {
    zone: 'America/Resolute',
    displayName: '(GMT-05:00) Central Daylight Time(America/Resolute)',
  },
  {
    zone: 'America/Rankin_Inlet',
    displayName: '(GMT-05:00) Central Daylight Time(America/Rankin_Inlet)',
  },
  {
    zone: 'America/Rainy_River',
    displayName: '(GMT-05:00) Central Daylight Time(America/Rainy_River)',
  },
  {
    zone: 'America/North_Dakota/New_Salem',
    displayName:
      '(GMT-05:00) Central Daylight Time(America/North_Dakota/New_Salem)',
  },
  {
    zone: 'America/North_Dakota/Center',
    displayName:
      '(GMT-05:00) Central Daylight Time(America/North_Dakota/Center)',
  },
  {
    zone: 'America/North_Dakota/Beulah',
    displayName:
      '(GMT-05:00) Central Daylight Time(America/North_Dakota/Beulah)',
  },
  {
    zone: 'America/Monterrey',
    displayName: '(GMT-05:00) Central Daylight Time(America/Monterrey)',
  },
  {
    zone: 'America/Mexico_City',
    displayName: '(GMT-05:00) Central Daylight Time(America/Mexico_City)',
  },
  {
    zone: 'America/Merida',
    displayName: '(GMT-05:00) Central Daylight Time(America/Merida)',
  },
  {
    zone: 'America/Menominee',
    displayName: '(GMT-05:00) Central Daylight Time(America/Menominee)',
  },
  {
    zone: 'America/Matamoros',
    displayName: '(GMT-05:00) Central Daylight Time(America/Matamoros)',
  },
  {
    zone: 'America/Knox_IN',
    displayName: '(GMT-05:00) Central Daylight Time(America/Knox_IN)',
  },
  {
    zone: 'America/Indiana/Tell_City',
    displayName: '(GMT-05:00) Central Daylight Time(America/Indiana/Tell_City)',
  },

  {
    zone: 'America/Indiana/Knox',
    displayName: '(GMT-05:00) Central Daylight Time(America/Indiana/Knox)',
  },
  {
    zone: 'America/Chicago',
    displayName: '(GMT-05:00) Central Daylight Time(America/Chicago)',
  },
  {
    zone: 'America/Bahia_Banderas',
    displayName: '(GMT-05:00) Central Daylight Time(America/Bahia_Banderas)',
  },
  {
    zone: 'America/Rio_Branco',
    displayName: '(GMT-05:00) Acre Standard Time(America/Rio_Branco)',
  },
  {
    zone: 'America/Porto_Acre',
    displayName: '(GMT-05:00) Acre Standard Time(America/Porto_Acre)',
  },
  {
    zone: 'America/Eirunepe',
    displayName: '(GMT-05:00) Acre Standard Time(America/Eirunepe)',
  },
  {
    zone: 'America/Caracas',
    displayName: '(GMT-04:00) Venezuela Time(America/Caracas)',
  },
  {
    zone: 'America/Guyana',
    displayName: '(GMT-04:00) Guyana Time(America/Guyana)',
  },
  {
    zone: 'America/Toronto',
    displayName: '(GMT-04:00) Eastern Daylight Time(America/Toronto)',
  },
  {
    zone: 'America/Thunder_Bay',
    displayName: '(GMT-04:00) Eastern Daylight Time(America/Thunder_Bay)',
  },
  {
    zone: 'America/Port-au-Prince',
    displayName: '(GMT-04:00) Eastern Daylight Time(America/Port-au-Prince)',
  },
  {
    zone: 'America/Pangnirtung',
    displayName: '(GMT-04:00) Eastern Daylight Time(America/Pangnirtung)',
  },
  {
    zone: 'America/Nipigon',
    displayName: '(GMT-04:00) Eastern Daylight Time(America/Nipigon)',
  },
  {
    zone: 'America/New_York',
    displayName: '(GMT-04:00) Eastern Daylight Time(America/New_York)',
  },
  {
    zone: 'America/Nassau',
    displayName: '(GMT-04:00) Eastern Daylight Time(America/Nassau)',
  },
  {
    zone: 'America/Montreal',
    displayName: '(GMT-04:00) Eastern Daylight Time(America/Montreal)',
  },

  {
    zone: 'America/Louisville',
    displayName: '(GMT-04:00) Eastern Daylight Time(America/Louisville)',
  },
  {
    zone: 'America/Kentucky/Monticello',
    displayName:
      ' (GMT-04:00) Eastern Daylight Time(America/Kentucky/Monticello)',
  },
  {
    zone: 'America/Kentucky/Louisville',
    displayName:
      ' (GMT-04:00) Eastern Daylight Time(America/Kentucky/Louisville)',
  },
  {
    zone: 'America/Iqaluit',
    displayName: '(GMT-04:00) Eastern Daylight Time(America/Iqaluit)',
  },
  {
    zone: 'America/Indianapolis',
    displayName: '(GMT-04:00) Eastern Daylight Time(America/Indianapolis)',
  },
  {
    zone: 'America/Indiana/Winamac',
    displayName: ' (GMT-04:00) Eastern Daylight Time(America/Indiana/Winamac)',
  },
  {
    zone: 'America/Indiana/Vincennes',
    displayName: '(GMT-04:00) Eastern Daylight Time(America/Indiana/Vincennes)',
  },
  {
    zone: 'America/Indiana/Vevay',
    displayName: '(GMT-04:00) Eastern Daylight Time(America/Indiana/Vevay)',
  },
  {
    zone: 'America/Indiana/Petersburg',
    displayName:
      '(GMT-04:00) Eastern Daylight Time(America/Indiana/Petersburg)',
  },
  {
    zone: 'America/Indiana/Marengo',
    displayName: '(GMT-04:00) Eastern Daylight Time(America/Indiana/Marengo)',
  },
  {
    zone: 'America/Indiana/Indianapolis',
    displayName:
      '(GMT-04:00) Eastern Daylight Time(America/Indiana/Indianapolis)',
  },
  {
    zone: 'America/Grand_Turk',
    displayName: '(GMT-04:00) Eastern Daylight Time(America/Grand_Turk)',
  },
  {
    zone: 'America/Fort_Wayne',
    displayName: '(GMT-04:00) Eastern Daylight Time(America/Fort_Wayne)',
  },
  {
    zone: 'America/Detroit',
    displayName: '(GMT-04:00) Eastern Daylight Time(America/Detroit)',
  },
  {
    zone: 'America/Havana',
    displayName: '(GMT-04:00) Cuba Daylight Time(America/Havana)',
  },
  {
    zone: 'America/La_Paz',
    displayName: '(GMT-04:00) Bolivia Time(America/La_Paz)',
  },
  {
    zone: 'America/Virgin',
    displayName: '(GMT-04:00) Atlantic Standard Time(America/Virgin)',
  },
  {
    zone: 'America/Tortola',
    displayName: '(GMT-04:00) Atlantic Standard Time(America/Tortola)',
  },
  {
    zone: 'America/St_Vincent',
    displayName: '(GMT-04:00) Atlantic Standard Time(America/St_Vincent)',
  },
  {
    zone: 'America/St_Thomas',
    displayName: '(GMT-04:00) Atlantic Standard Time(America/St_Thomas)',
  },
  {
    zone: 'America/St_Lucia',
    displayName: '(GMT-04:00) Atlantic Standard Time(America/St_Lucia)',
  },
  {
    zone: 'America/St_Kitts',
    displayName: '(GMT-04:00) Atlantic Standard Time(America/St_Kitts)',
  },
  {
    zone: 'America/St_Barthelemy',
    displayName: '(GMT-04:00) Atlantic StandardTime(America/St_Barthelemy)',
  },
  {
    zone: 'America/Santo_Domingo',
    displayName: '(GMT-04:00) Atlantic StandardTime(America/Santo_Domingo)',
  },
  {
    zone: 'America/Puerto_Rico',
    displayName: '(GMT-04:00) Atlantic Standard Time(America/Puerto_Rico)',
  },
  {
    zone: 'America/Port_of_Spain',
    displayName: '(GMT-04:00) Atlantic Standard Time(America/Port_of_Spain)',
  },
  {
    zone: 'America/Montserrat',
    displayName: '(GMT-04:00) Atlantic Standard Time(America/Montserrat)',
  },
  {
    zone: 'America/Martinique',
    displayName: '(GMT-04:00) Atlantic Standard Time(America/Martinique)',
  },
  {
    zone: 'America/Marigot',
    displayName: '(GMT-04:00) Atlantic Standard Time(America/Marigot)',
  },
  {
    zone: 'America/Lower_Princes',
    displayName: '(GMT-04:00) Atlantic Standard Time(America/Lower_Princes)',
  },
  {
    zone: 'America/Kralendijk',
    displayName: '(GMT-04:00) Atlantic Standard Time(America/Kralendijk)',
  },
  {
    zone: 'America/Guadeloupe',
    displayName: '(GMT-04:00) Atlantic Standard Time(America/Guadeloupe)',
  },
  {
    zone: 'America/Grenada',
    displayName: '(GMT-04:00) Atlantic Standard Time(America/Grenada)',
  },
  {
    zone: 'America/Dominica',
    displayName: '(GMT-04:00) Atlantic Standard Time(America/Dominica)',
  },
  {
    zone: 'America/Curacao',
    displayName: '(GMT-04:00) Atlantic Standard Time(America/Curacao)',
  },
  {
    zone: 'America/Blanc-Sablon',
    displayName: '(GMT-04:00) Atlantic Standard Time(America/Blanc-Sablon)',
  },
  {
    zone: 'America/Barbados',
    displayName: '(GMT-04:00) Atlantic Standard Time(America/Barbados)',
  },
  {
    zone: 'America/Aruba',
    displayName: '(GMT-04:00) Atlantic Standard Time(America/Aruba)',
  },
  {
    zone: 'America/Antigua',
    displayName: '(GMT-04:00) Atlantic Standard Time(America/Antigua)',
  },
  {
    zone: 'America/Anguilla',
    displayName: '(GMT-04:00) Atlantic Standard Time(America/Anguilla)',
  },
  {
    zone: 'America/Porto_Velho',
    displayName: '(GMT-04:00) Amazon Standard Time(America/Porto_Velho)',
  },
  {
    zone: 'America/Manaus',
    displayName: '(GMT-04:00) Amazon Standard Time(America/Manaus)',
  },
  {
    zone: 'America/Cuiaba',
    displayName: '(GMT-04:00) Amazon Standard Time(America/Cuiaba)',
  },
  {
    zone: 'America/Campo_Grande',
    displayName: '(GMT-04:00) Amazon Standard Time(America/Campo_Grande)',
  },
  {
    zone: 'America/Boa_Vista',
    displayName: '(GMT-04:00) Amazon Standard Time(America/Boa_Vista)',
  },

  {
    zone: 'America/Argentina/San_Luis',
    displayName:
      '(GMT-03:00) Western Argentina Standard Time(America/Argentina/San_Luis)',
  },
  {
    zone: 'America/Montevideo',
    displayName: '(GMT-03:00) Uruguay Standard Time(America/Montevideo)',
  },
  {
    zone: 'America/Paramaribo',
    displayName: '(GMT-03:00) Suriname Time(America/Paramaribo)',
  },
  {
    zone: 'America/Punta_Arenas',
    displayName: '(GMT-03:00) Punta Arenas Standard Time(America/Punta_Arenas)',
  },
  {
    zone: 'America/Asuncion',
    displayName: '(GMT-03:00) Paraguay Daylight Time(America/Asuncion)',
  },
  {
    zone: 'America/Cayenne',
    displayName: '(GMT-03:00) French Guiana Time(America/Cayenne)',
  },
  {
    zone: 'Atlantic/Stanley',
    displayName: '(GMT-03:00) Falkland Islands Standard Time(Atlantic/Stanley)',
  },
  {
    zone: 'America/Santiago',
    displayName: '(GMT-03:00) Chile Daylight Time(America/Santiago)',
  },
  {
    zone: 'America/Sao_Paulo',
    displayName: '(GMT-03:00) Brasilia Standard Time(America/Sao_Paulo)',
  },
  {
    zone: 'America/Santarem',
    displayName: '(GMT-03:00) Brasilia Standard Time(America/Santarem)',
  },
  {
    zone: 'America/Recife',
    displayName: '(GMT-03:00) Brasilia Standard Time(America/Recife)',
  },
  {
    zone: 'America/Maceio',
    displayName: '(GMT-03:00) Brasilia Standard Time(America/Maceio)',
  },
  {
    zone: 'America/Fortaleza',
    displayName: '(GMT-03:00) Brasilia Standard Time(America/Fortaleza)',
  },
  {
    zone: 'America/Belem',
    displayName: '(GMT-03:00) Brasilia Standard Time(America/Belem)',
  },
  {
    zone: 'America/Bahia',
    displayName: '(GMT-03:00) Brasilia Standard Time(America/Bahia)',
  },
  {
    zone: 'America/Araguaina',
    displayName: '(GMT-03:00) Brasilia Standard Time(America/Araguaina)',
  },
  {
    zone: 'Atlantic/Bermuda',
    displayName: '(GMT-03:00) Atlantic Daylight Time(Atlantic/Bermuda)',
  },
  {
    zone: 'America/Thule',
    displayName: '(GMT-03:00) Atlantic Daylight Time(America/Thule)',
  },
  {
    zone: 'America/Moncton',
    displayName: '(GMT-03:00) Atlantic Daylight Time(America/Moncton)',
  },
  {
    zone: 'America/Halifax',
    displayName: '(GMT-03:00) Atlantic Daylight Time(America/Halifax)',
  },
  {
    zone: 'America/Goose_Bay',
    displayName: '(GMT-03:00) Atlantic Daylight Time(America/Goose_Bay)',
  },
  {
    zone: 'America/Glace_Bay',
    displayName: '(GMT-03:00) Atlantic Daylight Time(America/Glace_Bay)',
  },
  {
    zone: 'America/Rosario',
    displayName: '(GMT-03:00) Argentina Standard Time(America/Rosario)',
  },

  {
    zone: 'America/Mendoza',
    displayName: '(GMT-03:00) Argentina Standard Time(America/Mendoza)',
  },
  {
    zone: 'America/Jujuy',
    displayName: '(GMT-03:00) Argentina Standard Time(America/Jujuy)',
  },
  {
    zone: 'America/Cordoba',
    displayName: '(GMT-03:00) Argentina Standard Time(America/Cordoba)',
  },
  {
    zone: 'America/Catamarca',
    displayName: '(GMT-03:00) Argentina Standard Time(America/Catamarca)',
  },
  {
    zone: 'America/Buenos_Aires',
    displayName: '(GMT-03:00) Argentina StandardTime(America/Buenos_Aires)',
  },
  {
    zone: 'America/Argentina/Ushuaia',
    displayName:
      '(GMT-03:00) Argentina StandardTime(America/Argentina/Ushuaia)',
  },
  {
    zone: 'America/Argentina/Tucuman',
    displayName:
      '(GMT-03:00) Argentina Standard Time(America/Argentina/Tucuman)',
  },
  {
    zone: 'America/Argentina/San_Juan',
    displayName:
      '(GMT-03:00) Argentina Standard Time(America/Argentina/San_Juan)',
  },
  {
    zone: 'America/Argentina/Salta',
    displayName: '(GMT-03:00) Argentina Standard Time(America/Argentina/Salta)',
  },
  {
    zone: 'America/Argentina/Rio_Gallegos',
    displayName:
      '(GMT-03:00) Argentina Standard Time(America/Argentina/Rio_Gallegos)',
  },
  {
    zone: 'America/Argentina/Mendoza',
    displayName:
      '(GMT-03:00) Argentina Standard Time(America/Argentina/Mendoza)',
  },
  {
    zone: 'America/Argentina/La_Rioja',
    displayName:
      '(GMT-03:00) Argentina Standard Time(America/Argentina/La_Rioja)',
  },
  {
    zone: 'America/Argentina/Jujuy',
    displayName: '(GMT-03:00) Argentina Standard Time(America/Argentina/Jujuy)',
  },
  {
    zone: 'America/Argentina/Cordoba',
    displayName:
      '(GMT-03:00) Argentina Standard Time(America/Argentina/Cordoba)',
  },
  {
    zone: 'America/Argentina/ComodRivadavia',
    displayName:
      '(GMT-03:00) Argentina Standard Time(America/Argentina/ComodRivadavia)',
  },
  {
    zone: 'America/Argentina/Catamarca',
    displayName:
      '(GMT-03:00) Argentina Standard Time(America/Argentina/Catamarca)',
  },
  {
    zone: 'America/Argentina/Buenos_Aires',
    displayName:
      '(GMT-03:00) Argentina Standard Time(America/Argentina/Buenos_Aires)',
  },
  {
    zone: 'America/St_Johns',
    displayName: '(GMT-02:30) Newfoundland Daylight Time(America/St_Johns)',
  },
  {
    zone: 'America/Nuuk',
    displayName: '(GMT-02:00) Western Greenland Time(America/Nuuk)',
  },
  {
    zone: 'America/Godthab',
    displayName: '(GMT-02:00) West Greenland Daylight Time(America/Godthab)',
  },
  {
    zone: 'America/Miquelon',
    displayName:
      '(GMT-02:00) St. Pierre &amp; Miquelon Daylight Time(America/Miquelon)',
  },
  {
    zone: 'Atlantic/South_Georgia',
    displayName: '(GMT-02:00) South Georgia Time(Atlantic/South_Georgia)',
  },
  {
    zone: 'America/Noronha',
    displayName:
      '(GMT-02:00) Fernando de Noronha Standard Time(America/Noronha)',
  },
  {
    zone: 'Atlantic/Cape_Verde',
    displayName: '(GMT-01:00) Cape Verde Standard Time(Atlantic/Cape_Verde)',
  },
  {
    zone: 'Atlantic/Azores',
    displayName: '(GMT+00:00) Azores Daylight Time(Atlantic/Azores)',
  },
  {
    zone: 'America/Scoresbysund',
    displayName:
      '(GMT+00:00) East Greenland Daylight Time(America/Scoresbysund)',
  },
  {
    zone: 'Africa/Abidjan',
    displayName: '(GMT+00:00) Greenwich Mean Time(Africa/Abidjan)',
  },
  {
    zone: 'Africa/Accra',
    displayName: '(GMT+00:00) Greenwich Mean Time(Africa/Accra)',
  },
  {
    zone: 'Africa/Bamako',
    displayName: '(GMT+00:00) Greenwich Mean Time(Africa/Bamako)',
  },
  {
    zone: 'Africa/Banjul',
    displayName: '(GMT+00:00) Greenwich Mean Time(Africa/Banjul)',
  },
  {
    zone: 'Africa/Bissau',
    displayName: '(GMT+00:00) Greenwich Mean Time(Africa/Bissau)',
  },
  {
    zone: 'Africa/Conakry',
    displayName: '(GMT+00:00) Greenwich Mean Time(Africa/Conakry)',
  },
  {
    zone: 'Africa/Dakar',
    displayName: '(GMT+00:00) Greenwich Mean Time(Africa/Dakar)',
  },
  {
    zone: 'Africa/Freetown',
    displayName: '(GMT+00:00) Greenwich Mean Time(Africa/Freetown)',
  },
  {
    zone: 'Africa/Lome',
    displayName: '(GMT+00:00) Greenwich Mean Time(Africa/Lome)',
  },
  {
    zone: 'Africa/Monrovia',
    displayName: '(GMT+00:00) Greenwich Mean Time(Africa/Monrovia)',
  },
  {
    zone: 'Africa/Nouakchott',
    displayName: '(GMT+00:00) Greenwich Mean Time(Africa/Nouakchott)',
  },
  {
    zone: 'Africa/Ouagadougou',
    displayName: '(GMT+00:00) Greenwich Mean Time(Africa/Ouagadougou)',
  },
  {
    zone: 'Africa/Timbuktu',
    displayName: '(GMT+00:00) Greenwich Mean Time(Africa/Timbuktu)',
  },
  {
    zone: 'America/Danmarkshavn',
    displayName: '(GMT+00:00) Greenwich Mean Time(America/Danmarkshavn)',
  },
  {
    zone: 'Atlantic/Reykjavik',
    displayName: '(GMT+00:00) Greenwich Mean Time(Atlantic/Reykjavik)',
  },
  {
    zone: 'Atlantic/St_Helena',
    displayName: '(GMT+00:00) Greenwich Mean Time(Atlantic/St_Helena)',
  },
  {
    zone: 'Africa/Sao_Tome',
    displayName: '(GMT+00:00) West Africa Standard Time(Africa/Sao_Tome)',
  },
  {
    zone: 'Africa/Casablanca',
    displayName:
      '(GMT+00:00) Western European Standard Time(Africa/Casablanca)',
  },
  {
    zone: 'Africa/El_Aaiun',
    displayName: '(GMT+00:00) Western European Standard Time(Africa/El_Aaiun)',
  },
  {
    zone: 'Africa/Windhoek',
    displayName: '(GMT+01:00) Central Africa Time(Africa/Windhoek)',
  },
  {
    zone: 'Africa/Algiers',
    displayName: '(GMT+01:00) Central European Standard Time(Africa/Algiers)',
  },
  {
    zone: 'Africa/Tunis',
    displayName: '(GMT+01:00) Central European Standard Time(Africa/Tunis)',
  },
  {
    zone: 'Europe/Belfast',
    displayName: '(GMT+01:00) Greenwich Mean Time(Europe/Belfast)',
  },
  {
    zone: 'Europe/Dublin',
    displayName: '(GMT+01:00) Greenwich Mean Time(Europe/Dublin)',
  },
  {
    zone: 'Europe/Guernsey',
    displayName: '(GMT+01:00) Greenwich Mean Time(Europe/Guernsey)',
  },
  {
    zone: 'Europe/Isle_of_Man',
    displayName: '(GMT+01:00) Greenwich Mean Time(Europe/Isle_of_Man)',
  },
  {
    zone: 'Europe/Jersey',
    displayName: '(GMT+01:00) Greenwich Mean Time(Europe/Jersey)',
  },
  {
    zone: 'Europe/London',
    displayName: '(GMT+01:00) Greenwich Mean Time(Europe/London)',
  },
  {
    zone: 'Africa/Bangui',
    displayName: '(GMT+01:00) West Africa Standard Time(Africa/Bangui)',
  },
  {
    zone: 'Africa/Brazzaville',
    displayName: '(GMT+01:00) West Africa Standard Time(Africa/Brazzaville)',
  },
  {
    zone: 'Africa/Douala',
    displayName: '(GMT+01:00) West Africa Standard Time(Africa/Douala)',
  },
  {
    zone: 'Africa/Kinshasa',
    displayName: '(GMT+01:00) West Africa Standard Time(Africa/Kinshasa)',
  },
  {
    zone: 'Africa/Lagos',
    displayName: '(GMT+01:00) West Africa Standard Time(Africa/Lagos)',
  },
  {
    zone: 'Africa/Libreville',
    displayName: '(GMT+01:00) West Africa Standard Time(Africa/Libreville)',
  },
  {
    zone: 'Africa/Luanda',
    displayName: '(GMT+01:00) West Africa Standard Time(Africa/Luanda)',
  },
  {
    zone: 'Africa/Malabo',
    displayName: '(GMT+01:00) West Africa Standard Time(Africa/Malabo)',
  },
  {
    zone: 'Africa/Ndjamena',
    displayName: '(GMT+01:00) West Africa Standard Time(Africa/Ndjamena)',
  },
  {
    zone: 'Africa/Niamey',
    displayName: '(GMT+01:00) West Africa Standard Time(Africa/Niamey)',
  },
  {
    zone: 'Africa/Porto-Novo',
    displayName: '(GMT+01:00) West Africa Standard Time(Africa/Porto-Novo)',
  },
  {
    zone: 'Atlantic/Canary',
    displayName: '(GMT+01:00) Western European Daylight Time(Atlantic/Canary)',
  },
  {
    zone: 'Atlantic/Faeroe',
    displayName: '(GMT+01:00) Western European Daylight Time(Atlantic/Faeroe)',
  },
  {
    zone: 'Atlantic/Faroe',
    displayName: '(GMT+01:00) Western European Daylight Time(Atlantic/Faroe)',
  },
  {
    zone: 'Atlantic/Madeira',
    displayName: '(GMT+01:00) Western European Daylight Time(Atlantic/Madeira)',
  },
  {
    zone: 'Europe/Lisbon',
    displayName: '(GMT+01:00) Western European Daylight Time(Europe/Lisbon)',
  },
  {
    zone: 'Africa/Blantyre',
    displayName: '(GMT+02:00) Central Africa Time(Africa/Blantyre)',
  },
  {
    zone: 'Africa/Bujumbura',
    displayName: '(GMT+02:00) Central Africa Time(Africa/Bujumbura)',
  },
  {
    zone: 'Africa/Gaborone',
    displayName: '(GMT+02:00) Central Africa Time(Africa/Gaborone)',
  },
  {
    zone: 'Africa/Harare',
    displayName: '(GMT+02:00) Central Africa Time(Africa/Harare)',
  },
  {
    zone: 'Africa/Khartoum',
    displayName: '(GMT+02:00) Central Africa Time(Africa/Khartoum)',
  },
  {
    zone: 'Africa/Kigali',
    displayName: '(GMT+02:00) Central Africa Time(Africa/Kigali)',
  },
  {
    zone: 'Africa/Lubumbashi',
    displayName: '(GMT+02:00) Central Africa Time(Africa/Lubumbashi)',
  },
  {
    zone: 'Africa/Lusaka',
    displayName: '(GMT+02:00) Central Africa Time(Africa/Lusaka)',
  },
  {
    zone: 'Africa/Maputo',
    displayName: '(GMT+02:00) Central Africa Time(Africa/Maputo)',
  },
  {
    zone: 'Africa/Ceuta',
    displayName: '(GMT+02:00) Central European Daylight Time(Africa/Ceuta)',
  },
  {
    zone: 'Atlantic/Jan_Mayen',
    displayName:
      '(GMT+02:00) Central European Daylight Time(Atlantic/Jan_Mayen)',
  },
  {
    zone: 'Europe/Amsterdam',
    displayName: '(GMT+02:00) Central European Daylight Time(Europe/Amsterdam)',
  },
  {
    zone: 'Europe/Andorra',
    displayName: '(GMT+02:00) Central European Daylight Time(Europe/Andorra)',
  },
  {
    zone: 'Europe/Belgrade',
    displayName: '(GMT+02:00) Central European Daylight Time(Europe/Belgrade)',
  },
  {
    zone: 'Europe/Berlin',
    displayName: '(GMT+02:00) Central European Daylight Time(Europe/Berlin)',
  },
  {
    zone: 'Europe/Bratislava',
    displayName:
      '(GMT+02:00) Central European Daylight Time(Europe/Bratislava)',
  },
  {
    zone: 'Europe/Brussels',
    displayName: '(GMT+02:00) Central European Daylight Time(Europe/Brussels)',
  },
  {
    zone: 'Europe/Budapest',
    displayName: '(GMT+02:00) Central European Daylight Time(Europe/Budapest)',
  },
  {
    zone: 'Europe/Busingen',
    displayName: '(GMT+02:00) Central European Daylight Time(Europe/Busingen)',
  },
  {
    zone: 'Europe/Copenhagen',
    displayName:
      '(GMT+02:00) Central European Daylight Time(Europe/Copenhagen)',
  },
  {
    zone: 'Europe/Gibraltar',
    displayName: '(GMT+02:00) Central European Daylight Time(Europe/Gibraltar)',
  },
  {
    zone: 'Europe/Ljubljana',
    displayName: '(GMT+02:00) Central European Daylight Time(Europe/Ljubljana)',
  },
  {
    zone: 'Europe/Luxembourg',
    displayName:
      '(GMT+02:00) Central European Daylight Time(Europe/Luxembourg)',
  },
  {
    zone: 'Europe/Madrid',
    displayName: '(GMT+02:00) Central European Daylight Time(Europe/Madrid)',
  },
  {
    zone: 'Europe/Malta',
    displayName: '(GMT+02:00) Central European Daylight Time(Europe/Malta)',
  },
  {
    zone: 'Europe/Monaco',
    displayName: '(GMT+02:00) Central European Daylight Time(Europe/Monaco)',
  },
  {
    zone: 'Europe/Oslo',
    displayName: '(GMT+02:00) Central European Daylight Time(Europe/Oslo)',
  },
  {
    zone: 'Europe/Paris',
    displayName: '(GMT+02:00) Central European Daylight Time(Europe/Paris)',
  },
  {
    zone: 'Europe/Podgorica',
    displayName: '(GMT+02:00) Central European Daylight Time(Europe/Podgorica)',
  },
  {
    zone: 'Europe/Prague',
    displayName: '(GMT+02:00) Central European Daylight Time(Europe/Prague)',
  },
  {
    zone: 'Europe/Rome',
    displayName: '(GMT+02:00) Central European Daylight Time(Europe/Rome)',
  },
  {
    zone: 'Europe/San_Marino',
    displayName:
      '(GMT+02:00) Central European Daylight Time(Europe/San_Marino)',
  },
  {
    zone: 'Europe/Sarajevo',
    displayName: '(GMT+02:00) Central European Daylight Time(Europe/Sarajevo)',
  },
  {
    zone: 'Europe/Skopje',
    displayName: '(GMT+02:00) Central European Daylight Time(Europe/Skopje)',
  },
  {
    zone: 'Europe/Stockholm',
    displayName: '(GMT+02:00) Central European Daylight Time(Europe/Stockholm)',
  },
  {
    zone: 'Europe/Tirane',
    displayName: '(GMT+02:00) Central European Daylight Time(Europe/Tirane)',
  },
  {
    zone: 'Europe/Vaduz',
    displayName: '(GMT+02:00) Central European Daylight Time(Europe/Vaduz)',
  },
  {
    zone: 'Europe/Vatican',
    displayName: '(GMT+02:00) Central European Daylight Time(Europe/Vatican)',
  },
  {
    zone: 'Europe/Vienna',
    displayName: '(GMT+02:00) Central European Daylight Time(Europe/Vienna)',
  },
  {
    zone: 'Europe/Warsaw',
    displayName: '(GMT+02:00) Central European Daylight Time(Europe/Warsaw)',
  },
  {
    zone: 'Europe/Zagreb',
    displayName: '(GMT+02:00) Central European Daylight Time(Europe/Zagreb)',
  },
  {
    zone: 'Europe/Zurich',
    displayName: '(GMT+02:00) Central European Daylight Time(Europe/Zurich)',
  },

  {
    zone: 'Africa/Juba',
    displayName: '(GMT+02:00) East Africa Time(Africa/Juba)',
  },

  {
    zone: 'Africa/Cairo',
    displayName: '(GMT+02:00) Eastern European Standard Time(Africa/Cairo)',
  },

  {
    zone: 'Africa/Tripoli',
    displayName: '(GMT+02:00) Eastern European Standard Time(Africa/Tripoli)',
  },
  {
    zone: 'Europe/Kaliningrad',
    displayName:
      '(GMT+02:00) Eastern European Standard Time(Europe/Kaliningrad)',
  },
  {
    zone: 'Africa/Johannesburg',
    displayName: '(GMT+02:00) South Africa Standard Time(Africa/Johannesburg)',
  },
  {
    zone: 'Africa/Maseru',
    displayName: '(GMT+02:00) South Africa Standard Time(Africa/Maseru)',
  },
  {
    zone: 'Africa/Mbabane',
    displayName: '(GMT+02:00) South Africa Standard Time(Africa/Mbabane)',
  },
  {
    zone: 'Asia/Aden',
    displayName: '(GMT+03:00) Arabian Standard Time(Asia/Aden)',
  },
  {
    zone: 'Asia/Baghdad',
    displayName: '(GMT+03:00) Arabian Standard Time(Asia/Baghdad)',
  },
  {
    zone: 'Asia/Bahrain',
    displayName: '(GMT+03:00) Arabian Standard Time(Asia/Bahrain)',
  },
  {
    zone: 'Asia/Kuwait',
    displayName: '(GMT+03:00) Arabian Standard Time(Asia/Kuwait)',
  },
  {
    zone: 'Asia/Qatar',
    displayName: '(GMT+03:00) Arabian Standard Time(Asia/Qatar)',
  },
  {
    zone: 'Asia/Riyadh',
    displayName: '(GMT+03:00) Arabian Standard Time(Asia/Riyadh)',
  },
  {
    zone: 'Africa/Addis_Ababa',
    displayName: '(GMT+03:00) East Africa Time(Africa/Addis_Ababa)',
  },
  {
    zone: 'Africa/Asmara',
    displayName: '(GMT+03:00) East Africa Time(Africa/Asmara)',
  },
  {
    zone: 'Africa/Asmera',
    displayName: '(GMT+03:00) East Africa Time(Africa/Asmera)',
  },
  {
    zone: 'Africa/Dar_es_Salaam',
    displayName: '(GMT+03:00) East Africa Time(Africa/Dar_es_Salaam)',
  },
  {
    zone: 'Africa/Djibouti',
    displayName: '(GMT+03:00) East Africa Time(Africa/Djibouti)',
  },
  {
    zone: 'Africa/Kampala',
    displayName: '(GMT+03:00) East Africa Time(Africa/Kampala)',
  },
  {
    zone: 'Africa/Mogadishu',
    displayName: '(GMT+03:00) East Africa Time(Africa/Mogadishu)',
  },
  {
    zone: 'Africa/Nairobi',
    displayName: '(GMT+03:00) East Africa Time(Africa/Nairobi)',
  },
  {
    zone: 'Indian/Antananarivo',
    displayName: '(GMT+03:00) East Africa Time(Indian/Antananarivo)',
  },
  {
    zone: 'Indian/Comoro',
    displayName: '(GMT+03:00) East Africa Time(Indian/Comoro)',
  },
  {
    zone: 'Indian/Mayotte',
    displayName: '(GMT+03:00) East Africa Time(Indian/Mayotte)',
  },
  {
    zone: 'Asia/Amman',
    displayName: '(GMT+03:00) Eastern European Daylight Time(Asia/Amman)',
  },
  {
    zone: 'Asia/Beirut',
    displayName: '(GMT+03:00) Eastern European Daylight Time(Asia/Beirut)',
  },
  {
    zone: 'Asia/Damascus',
    displayName: '(GMT+03:00) Eastern European Daylight Time(Asia/Damascus)',
  },
  {
    zone: 'Asia/Gaza',
    displayName: '(GMT+03:00) Eastern European Daylight Time(Asia/Gaza)',
  },
  {
    zone: 'Asia/Hebron',
    displayName: '(GMT+03:00) Eastern European Daylight Time(Asia/Hebron)',
  },
  {
    zone: 'Asia/Nicosia',
    displayName: '(GMT+03:00) Eastern European Daylight Time(Asia/Nicosia)',
  },
  {
    zone: 'Europe/Athens',
    displayName: '(GMT+03:00) Eastern European Daylight Time(Europe/Athens)',
  },
  {
    zone: 'Europe/Bucharest',
    displayName: '(GMT+03:00) Eastern European Daylight Time(Europe/Bucharest)',
  },
  {
    zone: 'Europe/Chisinau',
    displayName: '(GMT+03:00) Eastern European Daylight Time(Europe/Chisinau)',
  },
  {
    zone: 'Europe/Helsinki',
    displayName: '(GMT+03:00) Eastern European Daylight Time(Europe/Helsinki)',
  },
  {
    zone: 'Europe/Kiev',
    displayName: '(GMT+03:00) Eastern European Daylight Time(Europe/Kiev)',
  },
  {
    zone: 'Europe/Mariehamn',
    displayName: '(GMT+03:00) Eastern European Daylight Time(Europe/Mariehamn)',
  },
  {
    zone: 'Europe/Nicosia',
    displayName: '(GMT+03:00) Eastern European Daylight Time(Europe/Nicosia)',
  },
  {
    zone: 'Europe/Riga',
    displayName: '(GMT+03:00) Eastern European Daylight Time(Europe/Riga)',
  },
  {
    zone: 'Europe/Sofia',
    displayName: '(GMT+03:00) Eastern European Daylight Time(Europe/Sofia)',
  },
  {
    zone: 'Europe/Tallinn',
    displayName: '(GMT+03:00) Eastern European Daylight Time(Europe/Tallinn)',
  },
  {
    zone: 'Europe/Tiraspol',
    displayName: '(GMT+03:00) Eastern European Daylight Time(Europe/Tiraspol)',
  },
  {
    zone: 'Europe/Uzhgorod',
    displayName: '(GMT+03:00) Eastern European Daylight Time(Europe/Uzhgorod)',
  },
  {
    zone: 'Europe/Vilnius',
    displayName: '(GMT+03:00) Eastern European Daylight Time(Europe/Vilnius)',
  },
  {
    zone: 'Europe/Zaporozhye',
    displayName:
      '(GMT+03:00) Eastern European Daylight Time(Europe/Zaporozhye)',
  },
  {
    zone: 'Asia/Famagusta',
    displayName: '(GMT+03:00) Eastern European Time(Asia/Famagusta)',
  },
  {
    zone: 'Asia/Jerusalem',
    displayName: '(GMT+03:00) Israel Daylight Time(Asia/Jerusalem)',
  },
  {
    zone: 'Asia/Tel_Aviv',
    displayName: '(GMT+03:00) Israel Daylight Time(Asia/Tel_Aviv)',
  },
  {
    zone: 'Europe/Kirov',
    displayName: '(GMT+03:00) Kirov Standard Time(Europe/Kirov)',
  },
  {
    zone: 'Europe/Minsk',
    displayName: '(GMT+03:00) Moscow Standard Time(Europe/Minsk)',
  },
  {
    zone: 'Europe/Moscow',
    displayName: '(GMT+03:00) Moscow Standard Time(Europe/Moscow)',
  },
  {
    zone: 'Europe/Simferopol',
    displayName: '(GMT+03:00) Moscow Standard Time(Europe/Simferopol)',
  },
  {
    zone: 'Europe/Volgograd',
    displayName: '(GMT+03:00) Moscow Standard Time(Europe/Volgograd)',
  },
  {
    zone: 'Asia/Istanbul',
    displayName: '(GMT+03:00) Turkey Time(Asia/Istanbul)',
  },
  {
    zone: 'Europe/Istanbul',
    displayName: '(GMT+03:00) Turkey Time(Europe/Istanbul)',
  },
  {
    zone: 'Asia/Yerevan',
    displayName: '(GMT+04:00) Armenia Standard Time(Asia/Yerevan)',
  },
  {
    zone: 'Europe/Astrakhan',
    displayName: '(GMT+04:00) Astrakhan Standard Time(Europe/Astrakhan)',
  },
  {
    zone: 'Asia/Baku',
    displayName: '(GMT+04:00) Azerbaijan Standard Time(Asia/Baku)',
  },
  {
    zone: 'Asia/Tbilisi',
    displayName: '(GMT+04:00) Georgia Standard Time(Asia/Tbilisi)',
  },
  {
    zone: 'Asia/Dubai',
    displayName: '(GMT+04:00) Gulf Standard Time(Asia/Dubai)',
  },
  {
    zone: 'Asia/Muscat',
    displayName: '(GMT+04:00) Gulf Standard Time(Asia/Muscat)',
  },
  {
    zone: 'Indian/Mauritius',
    displayName: '(GMT+04:00) Mauritius Standard Time(Indian/Mauritius)',
  },
  {
    zone: 'Indian/Reunion',
    displayName: '(GMT+04:00) Réunion Time(Indian/Reunion)',
  },
  {
    zone: 'Europe/Samara',
    displayName: '(GMT+04:00) Samara Standard Time(Europe/Samara)',
  },
  {
    zone: 'Europe/Saratov',
    displayName: '(GMT+04:00) Saratov Standard Time(Europe/Saratov)',
  },
  {
    zone: 'Indian/Mahe',
    displayName: '(GMT+04:00) Seychelles Time(Indian/Mahe)',
  },
  {
    zone: 'Europe/Ulyanovsk',
    displayName: '(GMT+04:00) Ulyanovsk Standard Time(Europe/Ulyanovsk)',
  },
  {
    zone: 'Asia/Kabul',
    displayName: '(GMT+04:30) Afghanistan Time(Asia/Kabul)',
  },
  {
    zone: 'Asia/Tehran',
    displayName: '(GMT+04:30) Iran Daylight Time(Asia/Tehran)',
  },
  {
    zone: 'Asia/Qyzylorda',
    displayName: '(GMT+05:00) East Kazakhstan Time(Asia/Qyzylorda)',
  },
  {
    zone: 'Indian/Kerguelen',
    displayName:
      '(GMT+05:00) French Southern &amp; Antarctic Time(Indian/Kerguelen)',
  },
  {
    zone: 'Indian/Maldives',
    displayName: '(GMT+05:00) Maldives Time(Indian/Maldives)',
  },
  {
    zone: 'Asia/Karachi',
    displayName: '(GMT+05:00) Pakistan Standard Time(Asia/Karachi)',
  },
  {
    zone: 'Asia/Dushanbe',
    displayName: '(GMT+05:00) Tajikistan Time(Asia/Dushanbe)',
  },
  {
    zone: 'Asia/Ashgabat',
    displayName: '(GMT+05:00) Turkmenistan Standard Time(Asia/Ashgabat)',
  },
  {
    zone: 'Asia/Ashkhabad',
    displayName: '(GMT+05:00) Turkmenistan Standard Time(Asia/Ashkhabad)',
  },
  {
    zone: 'Asia/Samarkand',
    displayName: '(GMT+05:00) Uzbekistan Standard Time(Asia/Samarkand)',
  },
  {
    zone: 'Asia/Tashkent',
    displayName: '(GMT+05:00) Uzbekistan Standard Time(Asia/Tashkent)',
  },
  {
    zone: 'Asia/Aqtau',
    displayName: '(GMT+05:00) West Kazakhstan Time(Asia/Aqtau)',
  },
  {
    zone: 'Asia/Aqtobe',
    displayName: '(GMT+05:00) West Kazakhstan Time(Asia/Aqtobe)',
  },
  {
    zone: 'Asia/Atyrau',
    displayName: '(GMT+05:00) West Kazakhstan Time(Asia/Atyrau)',
  },
  {
    zone: 'Asia/Oral',
    displayName: '(GMT+05:00) West Kazakhstan Time(Asia/Oral)',
  },
  {
    zone: 'Asia/Yekaterinburg',
    displayName: '(GMT+05:00) Yekaterinburg Standard Time(Asia/Yekaterinburg)',
  },
  {
    zone: 'Asia/Calcutta',
    displayName: '(GMT+05:30) India Standard Time(Asia/Calcutta)',
  },
  {
    zone: 'Asia/Colombo',
    displayName: '(GMT+05:30) India Standard Time(Asia/Colombo)',
  },
  {
    zone: 'Asia/Kolkata',
    displayName: '(GMT+05:30) India Standard Time(Asia/Kolkata)',
  },
  {
    zone: 'Asia/Kathmandu',
    displayName: '(GMT+05:45) Nepal Time(Asia/Kathmandu)',
  },
  {
    zone: 'Asia/Katmandu',
    displayName: '(GMT+05:45) Nepal Time(Asia/Katmandu)',
  },
  {
    zone: 'Asia/Dacca',
    displayName: '(GMT+06:00) Bangladesh Standard Time(Asia/Dacca)',
  },
  {
    zone: 'Asia/Dhaka',
    displayName: '(GMT+06:00) Bangladesh Standard Time(Asia/Dhaka)',
  },
  {
    zone: 'Asia/Thimbu',
    displayName: '(GMT+06:00) Bhutan Time(Asia/Thimbu)',
  },
  {
    zone: 'Asia/Thimphu',
    displayName: '(GMT+06:00) Bhutan Time(Asia/Thimphu)',
  },
  {
    zone: 'Asia/Almaty',
    displayName: '(GMT+06:00) East Kazakhstan Time(Asia/Almaty)',
  },
  {
    zone: 'Indian/Chagos',
    displayName: '(GMT+06:00) Indian Ocean Time(Indian/Chagos)',
  },
  {
    zone: 'Asia/Qostanay',
    displayName: '(GMT+06:00) Kostanay Standard Time(Asia/Qostanay)',
  },
  {
    zone: 'Asia/Bishkek',
    displayName: '(GMT+06:00) Kyrgyzstan Time(Asia/Bishkek)',
  },
  {
    zone: 'Asia/Omsk',
    displayName: '(GMT+06:00) Omsk Standard Time(Asia/Omsk)',
  },
  {
    zone: 'Asia/Kashgar',
    displayName: '(GMT+06:00) Xinjiang Standard Time(Asia/Kashgar)',
  },
  {
    zone: 'Asia/Urumqi',
    displayName: '(GMT+06:00) Xinjiang Standard Time(Asia/Urumqi)',
  },
  {
    zone: 'Indian/Cocos',
    displayName: '(GMT+06:30) Cocos Islands Time(Indian/Cocos)',
  },
  {
    zone: 'Asia/Rangoon',
    displayName: '(GMT+06:30) Myanmar Time(Asia/Rangoon)',
  },
  {
    zone: 'Asia/Yangon',
    displayName: '(GMT+06:30) Myanmar Time(Asia/Yangon)',
  },
  {
    zone: 'Asia/Barnaul',
    displayName: '(GMT+07:00) Barnaul Standard Time(Asia/Barnaul)',
  },
  {
    zone: 'Indian/Christmas',
    displayName: '(GMT+07:00) Christmas Island Time(Indian/Christmas)',
  },
  {
    zone: 'Asia/Hovd',
    displayName: '(GMT+07:00) Hovd Standard Time(Asia/Hovd)',
  },
  {
    zone: 'Asia/Bangkok',
    displayName: '(GMT+07:00) Indochina Time(Asia/Bangkok)',
  },
  {
    zone: 'Asia/Ho_Chi_Minh',
    displayName: '(GMT+07:00) Indochina Time(Asia/Ho_Chi_Minh)',
  },
  {
    zone: 'Asia/Phnom_Penh',
    displayName: '(GMT+07:00) Indochina Time(Asia/Phnom_Penh)',
  },
  {
    zone: 'Asia/Saigon',
    displayName: '(GMT+07:00) Indochina Time(Asia/Saigon)',
  },
  {
    zone: 'Asia/Vientiane',
    displayName: '(GMT+07:00) Indochina Time(Asia/Vientiane)',
  },
  {
    zone: 'Asia/Krasnoyarsk',
    displayName: '(GMT+07:00) Krasnoyarsk Standard Time(Asia/Krasnoyarsk)',
  },
  {
    zone: 'Asia/Novokuznetsk',
    displayName: '(GMT+07:00) Krasnoyarsk Standard Time(Asia/Novokuznetsk)',
  },
  {
    zone: 'Asia/Novosibirsk',
    displayName: '(GMT+07:00) Novosibirsk Standard Time(Asia/Novosibirsk)',
  },
  {
    zone: 'Asia/Tomsk',
    displayName: '(GMT+07:00) Tomsk Standard Time(Asia/Tomsk)',
  },
  {
    zone: 'Asia/Jakarta',
    displayName: '(GMT+07:00) Western Indonesia Time(Asia/Jakarta)',
  },
  {
    zone: 'Asia/Pontianak',
    displayName: '(GMT+07:00) Western Indonesia Time(Asia/Pontianak)',
  },
  {
    zone: 'Australia/Perth',
    displayName:
      '(GMT+08:00) Australian Western Standard Time(Australia/Perth)',
  },
  {
    zone: 'Australia/West',
    displayName: '(GMT+08:00) Australian Western Standard Time(Australia/West)',
  },
  {
    zone: 'Asia/Brunei',
    displayName: '(GMT+08:00) Brunei Darussalam Time(Asia/Brunei)',
  },
  {
    zone: 'Asia/Makassar',
    displayName: '(GMT+08:00) Central Indonesia Time(Asia/Makassar)',
  },
  {
    zone: 'Asia/Ujung_Pandang',
    displayName: '(GMT+08:00) Central Indonesia Time(Asia/Ujung_Pandang)',
  },
  {
    zone: 'Asia/Chongqing',
    displayName: '(GMT+08:00) China Standard Time(Asia/Chongqing)',
  },
  {
    zone: 'Asia/Chungking',
    displayName: '(GMT+08:00) China Standard Time(Asia/Chungking)',
  },
  {
    zone: 'Asia/Harbin',
    displayName: '(GMT+08:00) China Standard Time(Asia/Harbin)',
  },
  {
    zone: 'Asia/Macao',
    displayName: '(GMT+08:00) China Standard Time(Asia/Macao)',
  },
  {
    zone: 'Asia/Macau',
    displayName: '(GMT+08:00) China Standard Time(Asia/Macau)',
  },
  {
    zone: 'Asia/Shanghai',
    displayName: '(GMT+08:00) China Standard Time(Asia/Shanghai)',
  },
  {
    zone: 'Asia/Choibalsan',
    displayName: '(GMT+08:00) Choibalsan Standard Time(Asia/Choibalsan)',
  },
  {
    zone: 'Asia/Hong_Kong',
    displayName: '(GMT+08:00) Hong Kong Standard Time(Asia/Hong_Kong)',
  },
  {
    zone: 'Asia/Irkutsk',
    displayName: '(GMT+08:00) Irkutsk Standard Time(Asia/Irkutsk)',
  },
  {
    zone: 'Asia/Kuala_Lumpur',
    displayName: '(GMT+08:00) Malaysia Time(Asia/Kuala_Lumpur)',
  },
  {
    zone: 'Asia/Kuching',
    displayName: '(GMT+08:00) Malaysia Time(Asia/Kuching)',
  },
  {
    zone: 'Asia/Manila',
    displayName: '(GMT+08:00) Philippine Standard Time(Asia/Manila)',
  },
  {
    zone: 'Asia/Singapore',
    displayName: '(GMT+08:00) Singapore Standard Time(Asia/Singapore)',
  },
  {
    zone: 'Asia/Taipei',
    displayName: '(GMT+08:00) Taipei Standard Time(Asia/Taipei)',
  },
  {
    zone: 'Asia/Ulaanbaatar',
    displayName: '(GMT+08:00) Ulaanbaatar Standard Time(Asia/Ulaanbaatar)',
  },
  {
    zone: 'Asia/Ulan_Bator',
    displayName: '(GMT+08:00) Ulaanbaatar Standard Time(Asia/Ulan_Bator)',
  },
  {
    zone: 'Australia/Eucla',
    displayName:
      '(GMT+08:45) Australian Central Western Standard Time(Australia/Eucla)',
  },
  {
    zone: 'Asia/Dili',
    displayName: '(GMT+09:00) East Timor Time(Asia/Dili)',
  },
  {
    zone: 'Asia/Jayapura',
    displayName: '(GMT+09:00) Eastern Indonesia Time(Asia/Jayapura)',
  },
  {
    zone: 'Asia/Tokyo',
    displayName: '(GMT+09:00) Japan Standard Time(Asia/Tokyo)',
  },
  {
    zone: 'Asia/Seoul',
    displayName: '(GMT+09:00) Korean Standard Time(Asia/Seoul)',
  },
  {
    zone: 'Pacific/Palau',
    displayName: '(GMT+09:00) Palau Time(Pacific/Palau)',
  },
  {
    zone: 'Asia/Pyongyang',
    displayName: '(GMT+09:00) Pyongyang Time(Asia/Pyongyang)',
  },
  {
    zone: 'Asia/Chita',
    displayName: '(GMT+09:00) Yakutsk Standard Time(Asia/Chita)',
  },
  {
    zone: 'Asia/Khandyga',
    displayName: '(GMT+09:00) Yakutsk Standard Time(Asia/Khandyga)',
  },
  {
    zone: 'Asia/Yakutsk',
    displayName: '(GMT+09:00) Yakutsk Standard Time(Asia/Yakutsk)',
  },
  {
    zone: 'Australia/Darwin',
    displayName:
      '(GMT+09:30) Australian Central Standard Time(Australia/Darwin)',
  },
  {
    zone: 'Australia/North',
    displayName:
      '(GMT+09:30) Australian Central Standard Time(Australia/North)',
  },
  {
    zone: 'Australia/Brisbane',
    displayName:
      '(GMT+10:00) Australian Eastern Standard Time(Australia/Brisbane)',
  },
  {
    zone: 'Australia/Lindeman',
    displayName:
      '(GMT+10:00) Australian Eastern Standard Time(Australia/Lindeman)',
  },
  {
    zone: 'Australia/Queensland',
    displayName:
      '(GMT+10:00) Australian Eastern Standard Time(Australia/Queensland)',
  },
  {
    zone: 'Pacific/Guam',
    displayName: '(GMT+10:00) Chamorro Standard Time(Pacific/Guam)',
  },
  {
    zone: 'Pacific/Saipan',
    displayName: '(GMT+10:00) Chamorro Standard Time(Pacific/Saipan)',
  },
  {
    zone: 'Pacific/Chuuk',
    displayName: '(GMT+10:00) Chuuk Time(Pacific/Chuuk)',
  },
  {
    zone: 'Pacific/Truk',
    displayName: '(GMT+10:00) Chuuk Time(Pacific/Truk)',
  },
  {
    zone: 'Pacific/Yap',
    displayName: '(GMT+10:00) Chuuk Time(Pacific/Yap)',
  },
  {
    zone: 'Pacific/Port_Moresby',
    displayName: '(GMT+10:00) Papua New Guinea Time(Pacific/Port_Moresby)',
  },
  {
    zone: 'Asia/Ust-Nera',
    displayName: '(GMT+10:00) Vladivostok Standard Time(Asia/Ust-Nera)',
  },
  {
    zone: 'Asia/Vladivostok',
    displayName: '(GMT+10:00) Vladivostok Standard Time(Asia/Vladivostok)',
  },
  {
    zone: 'Australia/Adelaide',
    displayName:
      '(GMT+10:30) Australian Central Daylight Time(Australia/Adelaide)',
  },
  {
    zone: 'Australia/Broken_Hill',
    displayName:
      '(GMT+10:30) Australian Central Daylight Time(Australia/Broken_Hill)',
  },
  {
    zone: 'Australia/South',
    displayName:
      '(GMT+10:30) Australian Central Daylight Time(Australia/South)',
  },
  {
    zone: 'Australia/Yancowinna',
    displayName:
      '(GMT+10:30) Australian Central Daylight Time(Australia/Yancowinna)',
  },
  {
    zone: 'Australia/ACT',
    displayName: '(GMT+11:00) Australian Eastern Daylight Time(Australia/ACT)',
  },
  {
    zone: 'Australia/Canberra',
    displayName:
      '(GMT+11:00) Australian Eastern Daylight Time(Australia/Canberra)',
  },
  {
    zone: 'Australia/Currie',
    displayName:
      '(GMT+11:00) Australian Eastern Daylight Time(Australia/Currie)',
  },
  {
    zone: 'Australia/Hobart',
    displayName:
      '(GMT+11:00) Australian Eastern Daylight Time(Australia/Hobart)',
  },
  {
    zone: 'Australia/Melbourne',
    displayName:
      '(GMT+11:00) Australian Eastern Daylight Time(Australia/Melbourne)',
  },
  {
    zone: 'Australia/NSW',
    displayName: '(GMT+11:00) Australian Eastern Daylight Time(Australia/NSW)',
  },
  {
    zone: 'Australia/Sydney',
    displayName:
      '(GMT+11:00) Australian Eastern Daylight Time(Australia/Sydney)',
  },
  {
    zone: 'Australia/Tasmania',
    displayName:
      '(GMT+11:00) Australian Eastern Daylight Time(Australia/Tasmania)',
  },
  {
    zone: 'Australia/Victoria',
    displayName:
      '(GMT+11:00) Australian Eastern Daylight Time(Australia/Victoria)',
  },
  {
    zone: 'Pacific/Bougainville',
    displayName: '(GMT+11:00) Bougainville Standard Time(Pacific/Bougainville)',
  },
  {
    zone: 'Pacific/Kosrae',
    displayName: '(GMT+11:00) Kosrae Time(Pacific/Kosrae)',
  },
  {
    zone: 'Australia/LHI',
    displayName: '(GMT+11:00) Lord Howe Daylight Time(Australia/LHI)',
  },
  {
    zone: 'Australia/Lord_Howe',
    displayName: '(GMT+11:00) Lord Howe Daylight Time(Australia/Lord_Howe)',
  },
  {
    zone: 'Asia/Magadan',
    displayName: '(GMT+11:00) Magadan Standard Time(Asia/Magadan)',
  },
  {
    zone: 'Pacific/Noumea',
    displayName: '(GMT+11:00) New Caledonia Standard Time(Pacific/Noumea)',
  },

  {
    zone: 'Pacific/Pohnpei',
    displayName: '(GMT+11:00) Ponape Time(Pacific/Pohnpei)',
  },
  {
    zone: 'Pacific/Ponape',
    displayName: '(GMT+11:00) Ponape Time(Pacific/Ponape)',
  },
  {
    zone: 'Asia/Sakhalin',
    displayName: '(GMT+11:00) Sakhalin Standard Time(Asia/Sakhalin)',
  },
  {
    zone: 'Pacific/Guadalcanal',
    displayName: '(GMT+11:00) Solomon Islands Time(Pacific/Guadalcanal)',
  },
  {
    zone: 'Asia/Srednekolymsk',
    displayName: '(GMT+11:00) Srednekolymsk Time(Asia/Srednekolymsk)',
  },
  {
    zone: 'Pacific/Efate',
    displayName: '(GMT+11:00) Vanuatu Standard Time(Pacific/Efate)',
  },
  {
    zone: 'Asia/Anadyr',
    displayName: '(GMT+12:00) Anadyr Standard Time(Asia/Anadyr)',
  },
  {
    zone: 'Pacific/Tarawa',
    displayName: '(GMT+12:00) Gilbert Islands Time(Pacific/Tarawa)',
  },
  {
    zone: 'Pacific/Kwajalein',
    displayName: '(GMT+12:00) Marshall Islands Time(Pacific/Kwajalein)',
  },
  {
    zone: 'Pacific/Majuro',
    displayName: '(GMT+12:00) Marshall Islands Time(Pacific/Majuro)',
  },
  {
    zone: 'Pacific/Nauru',
    displayName: '(GMT+12:00) Nauru Time(Pacific/Nauru)',
  },
  {
    zone: 'Pacific/Norfolk',
    displayName: '(GMT+12:00) Norfolk Island Time(Pacific/Norfolk)',
  },
  {
    zone: 'Asia/Kamchatka',
    displayName:
      '(GMT+12:00) Petropavlovsk-Kamchatski Standard Time(Asia/Kamchatka)',
  },
  {
    zone: 'Pacific/Funafuti',
    displayName: '(GMT+12:00) Tuvalu Time(Pacific/Funafuti)',
  },
  {
    zone: 'Pacific/Wake',
    displayName: '(GMT+12:00) Wake Island Time(Pacific/Wake)',
  },
  {
    zone: 'Pacific/Wallis',
    displayName: '(GMT+12:00) Wallis &amp; Futuna Time(Pacific/Wallis)',
  },

  {
    zone: 'Pacific/Fiji',
    displayName: '(GMT+13:00) Fiji Daylight Time(Pacific/Fiji)',
  },
  {
    zone: 'Pacific/Auckland',
    displayName: '(GMT+13:00) New Zealand Daylight Time(Pacific/Auckland)',
  },
  {
    zone: 'Pacific/Enderbury',
    displayName: '(GMT+13:00) Phoenix Islands Time(Pacific/Enderbury)',
  },
  {
    zone: 'Pacific/Fakaofo',
    displayName: '(GMT+13:00) Tokelau Time(Pacific/Fakaofo)',
  },
  {
    zone: 'Pacific/Tongatapu',
    displayName: '(GMT+13:00) Tonga Standard Time(Pacific/Tongatapu)',
  },
  {
    zone: 'Pacific/Chatham',
    displayName: '(GMT+13:45) Chatham Daylight Time(Pacific/Chatham)',
  },
  {
    zone: 'Pacific/Apia',
    displayName: '(GMT+14:00) Apia Daylight Time(Pacific/Apia)',
  },
  {
    zone: 'Pacific/Kiritimati',
    displayName: '(GMT+14:00) Line Islands Time(Pacific/Kiritimati)',
  },
];

export const timesList = [
  {
    label: '12:00 AM',
    value: '0',
  },
  {
    label: '01:00 AM',
    value: '1',
  },
  {
    label: '02:00 AM',
    value: '2',
  },
  {
    label: '03:00 AM',
    value: '3',
  },
  {
    label: '04:00 AM',
    value: '4',
  },
  {
    label: '05:00 AM',
    value: '5',
  },
  {
    label: '06:00 AM',
    value: '6',
  },
  {
    label: '07:00 AM',
    value: '7',
  },
  {
    label: '08:00 AM',
    value: '8',
  },
  {
    label: '09:00 AM',
    value: '9',
  },
  {
    label: '10:00 AM',
    value: '10',
  },
  {
    label: '11:00 AM',
    value: '11',
  },
  {
    label: '12:00 PM',
    value: '12',
  },
  {
    label: '01:00 PM',
    value: '13',
  },
  {
    label: '02:00 PM',
    value: '14',
  },
  {
    label: '03:00 PM',
    value: '15',
  },
  {
    label: '04:00 PM',
    value: '16',
  },
  {
    label: '05:00 PM',
    value: '17',
  },
  {
    label: '06:00 PM',
    value: '18',
  },
  {
    label: '07:00 PM',
    value: '19',
  },
  {
    label: '08:00 PM',
    value: '20',
  },
  {
    label: '09:00 PM',
    value: '21',
  },
  {
    label: '10:00 PM',
    value: '22',
  },
  {
    label: '11:00 PM',
    value: '23',
  },
];

// Below time list contains 30 mins interval timelist
export const timeList = [
  '12:00 AM',
  '12:30 AM',
  '1:00 AM',
  '1:30 AM',
  '2:00 AM',
  '2:30 AM',
  '3:00 AM',
  '3:30 AM',
  '4:00 AM',
  '4:30 AM',
  '5:00 AM',
  '5:30 AM',
  '6:00 AM',
  '6:30 AM',
  '7:00 AM',
  '7:30 AM',
  '8:00 AM',
  '8:30 AM',
  '9:00 AM',
  '9:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '1:00 PM',
  '1:30 PM',
  '2:00 PM',
  '2:30 PM',
  '3:00 PM',
  '3:30 PM',
  '4:00 PM',
  '4:30 PM',
  '5:00 PM',
  '5:30 PM',
  '6:00 PM',
  '6:30 PM',
  '7:00 PM',
  '7:30 PM',
  '8:00 PM',
  '8:30 PM',
  '9:00 PM',
  '9:30 PM',
  '10:00 PM',
  '10:30 PM',
  '11:00 PM',
  '11:30 PM',
];

export default getErrorMessage;

/*
 This is just triggr production build -- Ravi
 Trigger netlify DevQa build Jun 13 2022 -1
*/
