import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RestLink } from 'apollo-link-rest';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { parseUrl } from 'query-string';

import { useAuth0 } from '@auth0/auth0-react';

import { PageLoader } from '@koncert/shared-components';
import * as actions from '../store/actions/actions';
import { PUBLIC_CALENDAR_PAGE, PUBLIC_PAGES } from '../util';
import { clearBrowserCache, handleLogout } from '../util/index';
import ApiUrlAndTokenProvider from './ApiUrlAndTokenProvider';

const AuthenticationProvider = ({ actions, ...props }) => {
  // Define Cadence API url from environment variables
  const API_URL = process.env.REACT_APP_CADENCE_API_URL;

  const history = useHistory();

  // Validate whether current page can be accessed publicly
  const PRIVATE_PAGE =
    !PUBLIC_PAGES.some((page) => window.location.href.includes(page)) &&
    !window.location.href.includes(PUBLIC_CALENDAR_PAGE);

  const logoutChannel = new BroadcastChannel('logout');
  const reminderChannel = new BroadcastChannel('reminder');

  // Initialize Auth0 hook
  const {
    error,
    isAuthenticated,
    isLoading: loading,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  const [accessToken, setAccessToken] = useState();
  const [tokenCreatedDate, setTokenCreatedDate] = useState();
  const [tokenExpiresIn, setTokenExpiresIn] = useState();

  const [jcaValue, setJcaValue] = useState();
  /* ----- To handle logout links in 500 and InvalidLicense error pages -begin ----- */
  const pathname = window.location.pathname;
  const { query: searchParams } = parseUrl(window.location.search);
  if (!PRIVATE_PAGE && pathname === '/logout' && isAuthenticated) {
    let returnToLogin = false;
    if (searchParams.returnTo === 'login') {
      returnToLogin = true;
    }
    handleLogout(actions, logout, returnToLogin);
  }
  /* ----- To handle logout links in 500 and InvalidLicense error pages -end ----- */

  useEffect(() => {
    logoutChannel.addEventListener('message', (evt) => {
      if (evt.data.logoutMessage === "Oh! You've been logged out.") {
        handleLogout(actions, logout);
        logoutChannel.close();
      }
    });

    reminderChannel.addEventListener('message', (evt) => {
      if (
        evt.data.message === 'navigate:prospect-view' &&
        evt.data.prospectId &&
        window.name !== 'Reminders'
      ) {
        history.push({
          pathname: `/prospects/list/${evt.data.prospectId}`,
          state: { pathParam: 'reminders' },
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoutChannel, reminderChannel]);

  const handleGetAccessTokenSilently = () => {
    getAccessTokenSilently().then((token) => {
      // decoding the json web token's middle part which contains the payload
      const decoded = JSON.parse(atob(token.split('.')[1]));
      // calculate the expiration date
      const expiration = parseInt(decoded?.exp) - parseInt(decoded?.iat);
      setAccessToken(token);
      setTokenCreatedDate(new Date().getTime());
      setTokenExpiresIn(expiration);
    });
  };

  // Perform below validations if the page is private
  if (PRIVATE_PAGE) {
    if (
      [
        'noCrmAccountFound',
        'unableToLink',
        'crmOrgIdMismatch',
        'failedToUpdateCrmUserId',
      ].includes(error?.message)
    ) {
      logout({ returnTo: `${window.location.origin}/${error.message}` });

      return null;
    }

    if (error) {
      console.error(error);

      throw new Error('Failed to login');
    }

    if (loading) {
      return <PageLoader />;
    }

    // If user not autenticated redirect to Auth0 login page
    if (!isAuthenticated) {
      loginWithRedirect({
        appState: {
          returnTo: history.location.pathname + history.location.search,
        },
      });

      return null;
    }

    // If user autenticated and accessToken not obtained then get access token
    if (isAuthenticated && !accessToken) {
      clearBrowserCache(actions);
      // get token and save created date and expiration date
      handleGetAccessTokenSilently();
    }
  }

  // setup `timeoutLink`
  const timeoutLink = new ApolloLinkTimeout(20000); // 20 second timeout

  // Create error link to report Apollo client errors
  const errorLink = onError(({ response, networkError, ...props }) => {
    const statusCode = networkError?.statusCode;
    // if there is 401 error
    if (parseInt(statusCode) === 401) {
      const currentTime = new Date().getTime();
      // if the token is expired
      if (currentTime > tokenCreatedDate + tokenExpiresIn) {
        // get token again and save new created date and expiration date
        handleGetAccessTokenSilently();
      }
    }
    //When error occurred, if response includes requestId, return requestId as response data.
    //EX: const{data} = useQuery({{query}}), here data will have requestId, if error response is recevied and response contains requestId
    if (response?.requestId) {
      response.data = {
        requestId: response.requestId,
      };
    }
  });

  // Log error if user athenticated but resource url not available
  isAuthenticated && !API_URL && console.error('Resource url not found');

  // setup your `RestLink` with your endpoint
  const restLink = new RestLink({
    uri: API_URL,
  });

  // axios global defaults are available here
  axios.defaults.baseURL = API_URL;
  axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

  const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${accessToken}`,
      },
    };
  });

  // Initialize Apollo Client
  const client = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, timeoutLink, restLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all', // Required to parse requestId from response data when status code other than 200
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all', // Required to parse requestId from response data when status code other than 200
      },
    },
  });
  if (PRIVATE_PAGE && isAuthenticated && !accessToken) {
    return <PageLoader />;
  } else {
    return (
      <ApiUrlAndTokenProvider
        apiURL={API_URL}
        token={accessToken}
        jcaValue={jcaValue}
        setJcaValue={setJcaValue}
      >
        <ApolloProvider client={client}>{props.children}</ApolloProvider>
      </ApiUrlAndTokenProvider>
    );
  }
};

const mapDispatchToProps = function (dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(AuthenticationProvider);
