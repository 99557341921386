import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import UserContext from "./UserContext";
import LicenseUserContext from "./LicenseUserContext";
import { useTracking } from "@nextaction/components";

const ProtectedRoute = (props) => {
  const tracker = useTracking();
  const { user, loading: userLoading } = useContext(UserContext);
  const { user: licenseUser, loading: licenseUserLoading } = useContext(LicenseUserContext);
  if (userLoading || licenseUserLoading ) return null;

  if (!user || !licenseUser) return <Redirect to="/error500" />;

  if(user?.isFirstTimeUser&&props.path!=='/settings/onboard'){
    if(Object.keys(licenseUser?.associations).length === 0){
      window.location =process.env.REACT_APP_BASE_APP_URL;
      return;
    } else {
      return <Redirect to="/settings/onboard" />;
    }
   
  } else if (!user?.isFirstTimeUser && props.path==='/settings/onboard'){
    return <Redirect to="/" />;
  }

  const { component: Component, requireAdmin, computedMatch, ...rest } = props;
  if (computedMatch !== undefined) {
    tracker.page(computedMatch.url);
  }
  if (user.rolesMask !== 1 && requireAdmin === true)
    return <Redirect to="/error500" />;
  return <Route component={Component} {...rest} />;
};

export default ProtectedRoute;